import { useState, useCallback } from 'react';
import { COUNTRY_LIST } from '@apps/card/routes/CardCustomizer/components/AddressDialog/AddressDialog.controller';
import { isEmpty } from 'lodash-es';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import { useAuthenticateUserQuery, useGetContactCollectionByEventIdAndNamesByEventHandleQuery, useGetEventContactsQuery } from '@graphql/generated';
import axios from 'axios';
import { getConfig } from '@config';
import { useAuth } from '@shared/components/AuthProvider';
import { useEventInfo } from '@shared/utils/eventInfo';

const isDev = getConfig().environment === 'dev';

// TODO: Consume from FF
const COUNTRY_ALIASES: { [key: string]: string } = {
  'United States of America': 'United States',
  'US of A': 'United States',
  USA: 'United States',
  'U.S.A.': 'United States',
  US: 'United States',
  'U.S.': 'United States',
  Ca: 'Canada',
  Can: 'Canada',
  AU: 'Australia',
  Aus: 'Australia',
  NZ: 'New Zealand',
  UK: 'United Kingdom',
  'U.K.': 'United Kingdom',
  England: 'United Kingdom',
  Scotland: 'United Kingdom',
  Wales: 'United Kingdom',
  'Northern Ireland': 'United Kingdom',
  'Great Britain': 'United Kingdom',
  GB: 'United Kingdom',
  'G.B.': 'United Kingdom'
};
// TODO: Consume from FF
export const CONTACT_PROPERTIES_LABEL: { [key: string]: string } = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email (Optional)',
  phoneNumber: 'Phone Number (Optional)',
  householdDisplayName: 'Name on Envelope (Optional)',
  party: 'Party (Optional)',
  address1: 'Address Line 1 (Optional)',
  address2: 'Address Line 2 (Optional)',
  city: 'City (Optional)',
  region: 'State/Region (Optional)',
  postalCode: 'Postal Code (Optional)',
  country: 'Country (Optional)'
};

const getCountryNameFromAliases = (name: string): string => Object.entries(COUNTRY_ALIASES).find(value => value[0] === name)?.[1] ?? name;

// this is necessary due to a library limitation, when we have repeated values in a row a dash plus a number is added at the bottom
const replaceDashEmptyStrings = (value?: string): string => value?.replace(/(_)\d/g, '') ?? '';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasAllRequiredColumns = (csvContacts: any[]) =>
  csvContacts.every(csvContact => Object.hasOwn(csvContact, CONTACT_PROPERTIES_LABEL.firstName) && Object.hasOwn(csvContact, CONTACT_PROPERTIES_LABEL.lastName));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapCSVContactToContact = (csvContact: any): Contact => ({
  firstName: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.firstName]),
  lastName: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.lastName]),
  email: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.email]),
  phoneNumber: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.phoneNumber]),
  householdDisplayName: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.householdDisplayName]),
  party: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.party]),
  address1: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.address1]),
  address2: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.address2]),
  city: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.city]),
  region: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.region]),
  postalCode: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.postalCode]),
  country: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.country])
});

export const isValidContact = (value: Contact): value is Contact => !!(value.firstName && value.lastName);

const getCountryCodeByCountryName = (name: string): string => COUNTRY_LIST.find(({ label }) => label === name)?.value ?? '';

export const isValidPostalCode = (postalCode: string, country: string): boolean => {
  if (country) {
    const countryCode = getCountryCodeByCountryName(country);
    if (postcodeValidatorExistsForCountry(countryCode)) {
      return postcodeValidator(postalCode, countryCode);
    }
  }
  return true;
};

export const mapContactsToPayloadData = (contacts: Contact[], contactCollectionId: string, eventFirebaseId: string, eventId: string) => {
  return contacts.map(contact => {
    const isAddressEmpty =
      isEmpty(contact.address1) && isEmpty(contact.address2) && isEmpty(contact.city) && isEmpty(contact.country) && isEmpty(contact.postalCode) && isEmpty(contact.region);
    return {
      address: isAddressEmpty
        ? undefined
        : {
            address1: contact.address1,
            address2: contact.address2,
            city: contact.city,
            country: getCountryNameFromAliases(contact.country),
            countryCode: getCountryCodeByCountryName(getCountryNameFromAliases(contact.country)),
            postalCode: contact.postalCode,
            region: contact.region,
            validated: false
          },
      contact: {
        contactCollectionId,
        firebaseEventId: eventFirebaseId,
        eventId,
        email: contact.email ?? '',
        firstName: contact.firstName,
        lastName: contact.lastName,
        householdDisplayName: isEmpty(contact.householdDisplayName) ? undefined : contact.householdDisplayName,
        party: isEmpty(contact.party) ? undefined : contact.party
      },
      phoneNumbers: contact.phoneNumber
        ? [
            {
              allowSMS: true,
              mobile: true,
              number: contact.phoneNumber
            }
          ]
        : undefined
    };
  });
};

export const useCreateContacts = (context: 'print' | 'contactCollector', telemetryError: () => void) => {
  const [isCreateContactsLoading, setIsCreateContactsLoading] = useState<boolean>(false);
  const [hasCreateContactsError, setHasCreateContactsError] = useState<boolean>(false);
  const { eventInfo, eventHandle = '' } = useEventInfo();
  const eventId = eventInfo?.eventId ?? '';
  const firebaseEventId = eventInfo?.eventFirebaseId ?? '';
  const { loginManager } = useAuth();
  const authToken = loginManager.getToken();
  const { data: userJWTData, loading: isGetUserJWTLoading } = useAuthenticateUserQuery({
    variables: {
      authToken: authToken!
    },
    skip: !authToken,
    batchMode: 'fast'
  });
  const {
    loading: isGetContactCollectorContactsLoading,
    error: hasGetContactCollectorContactsError,
    refetch: getContactCollectorContacts
  } = useGetContactCollectionByEventIdAndNamesByEventHandleQuery({
    variables: { eventId, eventHandle },
    batchMode: 'fast',
    skip: true
  });
  const { loading: isGetPrintContactsLoading, error: hasGetPrintContactsError, refetch: getPrintContacts } = useGetEventContactsQuery({
    variables: { firebaseEventId, eventId, forceGuestListSynch: true },
    batchMode: 'fast',
    skip: true
  });
  const refetchQuery = context === 'contactCollector' ? getContactCollectorContacts : getPrintContacts;

  const createContacts = useCallback(
    async payload => {
      setIsCreateContactsLoading(true);
      try {
        if (!authToken || !userJWTData?.authenticateUser?.userJWT) {
          throw Error('Missing Headers');
        }
        await axios.post('/services/contact/contact/createContacts', payload, {
          baseURL: isDev ? 'https://dev.withjoy.com' : 'https://withjoy.com',
          headers: {
            Authorization: `Bearer ${authToken}`,
            'X-Joy-UserJWT': userJWTData.authenticateUser.userJWT
          }
        });
        await refetchQuery();
      } catch {
        telemetryError();
        setHasCreateContactsError(true);
      } finally {
        setIsCreateContactsLoading(false);
      }
    },
    [authToken, refetchQuery, telemetryError, userJWTData?.authenticateUser?.userJWT]
  );

  return {
    createContacts,
    isCreateContactsLoading: isCreateContactsLoading || isGetContactCollectorContactsLoading || isGetPrintContactsLoading,
    hasCreateContactsError: hasCreateContactsError || !!hasGetContactCollectorContactsError || !!hasGetPrintContactsError,
    isGetUserJWTLoading
  };
};
