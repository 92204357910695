import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';

const categoryByContext = { print: 'messaging.card', contactCollector: 'admin.contactcollector' };
const pageByContext = { print: 'customize', contactCollector: 'manage' };

const contactCollectorTelemetry = createTelemetryObject({
  actions: {
    trackError: (context: 'print' | 'contactCollector', action: 'Mutation' | 'Other', extraInfo) => ({
      category: categoryByContext[context],
      pagePrefix: 'admin',
      page: pageByContext[context],
      action,
      actionType: 'error',
      extraInfo
    }),
    buttonInteracted: (context: 'print' | 'contactCollector', extraInfo) => ({
      category: categoryByContext[context],
      pagePrefix: 'admin',
      page: pageByContext[context],
      action: 'MessagingButtonInteracted',
      extraInfo
    }),
    trackInvalidPostalCode: (context: 'print' | 'contactCollector', extraInfo) => ({
      category: categoryByContext[context],
      pagePrefix: 'admin',
      page: pageByContext[context],
      action: 'InvalidPostalCode',
      extraInfo
    })
  }
});

const { TelemetryProvider, useTelemetry } = createTelemetry(contactCollectorTelemetry);
const useCSVUploadTelemetry = useTelemetry;
export { TelemetryProvider, useCSVUploadTelemetry };
