import { useState, useEffect, useCallback } from 'react';
import { FormikProps } from 'formik';
import { useRouterHelper, useTranslation } from '@shared/core';
import { useContactCollectorGuestTelemetryTelemetry } from '../../ContactCollectorGuest.telemetry';
import { contactCollectorGuestRoutePaths } from '../..';
import { ContactInputFields } from '../../ContactCollectorGuest.controller';
import { AddressVerificationResponseType, useAddressVerification, useAddressAutocomplete, InternationalAddressResponseType } from '@apps/registry/common/utils/smartyClient';
import { NATIONAL_COUNTRY_CODE } from '@apps/admin/common/ShippingAddress/constants';

export interface SuggestedAddress extends Readonly<{ address: string; city: string; state: string; zipCode: string }> {}

export type AddressOption = 'original' | 'suggested';

export const useAddressController = (formik: FormikProps<ContactInputFields>) => {
  const { handleInternationalAddressSearchV2 } = useAddressAutocomplete();
  const { fetchAddressSuggestions } = useAddressVerification();
  const [isValidatingAddress, setIsValidatingAddress] = useState<boolean>(true);
  const [addressOption, setAddressOption] = useState<AddressOption>('original');
  const [suggestedAddress, setSuggestedAddress] = useState<SuggestedAddress | undefined>(undefined);
  const { buttonInteracted, trackError } = useContactCollectorGuestTelemetryTelemetry();
  const routeHelpers = useRouterHelper();
  const { t } = useTranslation('contactCollector');
  const tAdmin = t('admin');

  const handleInternationalAddressAutocomplete = useCallback(
    async (value: string, countryCode: string) => {
      const lookup = { search: value, country: countryCode, includeOnlyPostalCode: formik.values.postalCode };

      const response = await handleInternationalAddressSearchV2(lookup);
      return response;
    },
    [formik.values.postalCode, handleInternationalAddressSearchV2]
  );

  const fetchSuggestions = useCallback(async () => {
    try {
      const isNationalAddress = formik.values.countryCode === NATIONAL_COUNTRY_CODE;
      const suggestions = await (isNationalAddress
        ? fetchAddressSuggestions({ street: formik.values.address1, city: formik.values.city, state: formik.values.region, zipCode: formik.values.postalCode })
        : handleInternationalAddressAutocomplete(formik.values.address1, formik.values.countryCode));

      if (suggestions?.length) {
        setAddressOption('suggested');

        if (isNationalAddress) {
          const suggestion = suggestions[0] as AddressVerificationResponseType;
          const suggestionComponents = suggestion.components;
          setSuggestedAddress({
            address: suggestion.deliveryLine1,
            city: suggestionComponents.cityName,
            state: suggestionComponents.state,
            zipCode: suggestionComponents.zipCode
          });

          const areAddressesEqual =
            suggestion.deliveryLine1 === formik.values.address1.trim() &&
            suggestionComponents.cityName === formik.values.city.trim() &&
            suggestionComponents.state === formik.values.region.trim() &&
            suggestionComponents.zipCode === formik.values.postalCode.trim();

          if (areAddressesEqual) {
            formik.setValues({
              ...formik.values,
              address1: suggestion.deliveryLine1,
              city: suggestionComponents.cityName,
              region: suggestionComponents.state,
              postalCode: suggestionComponents.zipCode,
              validated: true
            });
            routeHelpers.goToExactRoute(contactCollectorGuestRoutePaths.confirmation.path);
          }
        } else {
          const suggestion = suggestions[0] as InternationalAddressResponseType;
          setSuggestedAddress({
            address: suggestion.street,
            city: suggestion.locality,
            state: suggestion.administrativeArea,
            zipCode: suggestion.postalCode
          });

          const areAddressesEqual =
            suggestion.street === formik.values.address1.trim() &&
            suggestion.locality === formik.values.city.trim() &&
            suggestion.administrativeArea === formik.values.region.trim() &&
            suggestion.postalCode === formik.values.postalCode.trim();

          if (areAddressesEqual) {
            formik.setValues({
              ...formik.values,
              address1: suggestion.street,
              city: suggestion.locality,
              region: suggestion.administrativeArea,
              postalCode: suggestion.postalCode,
              validated: true
            });
            routeHelpers.goToExactRoute(contactCollectorGuestRoutePaths.confirmation.path);
          }
        }
      } else {
        setAddressOption('original');
        setSuggestedAddress(undefined);
      }
    } catch (error) {
      trackError('Other', error);
      setAddressOption('original');
      setSuggestedAddress(undefined);
    }
    setIsValidatingAddress(false);
  }, [formik, handleInternationalAddressAutocomplete, fetchAddressSuggestions, routeHelpers, trackError]);

  useEffect(() => {
    if (!formik.isValid) {
      return routeHelpers.goToExactRoute(contactCollectorGuestRoutePaths.landing.path);
    }
    fetchSuggestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnConfirmAddressClick = () => {
    buttonInteracted({ label: 'ConfirmAddress', type: addressOption });
    if (addressOption === 'suggested' && suggestedAddress) {
      formik.setValues({
        ...formik.values,
        address1: suggestedAddress.address,
        city: suggestedAddress.city,
        region: suggestedAddress.state,
        postalCode: suggestedAddress.zipCode,
        validated: true
      });
    } else {
      formik.setValues({ ...formik.values, validated: false });
    }
    routeHelpers.goToExactRoute(contactCollectorGuestRoutePaths.confirmation.path);
  };

  const handleOnBackClick = () => {
    buttonInteracted({ label: 'ConfirmAddressBack' });
    routeHelpers.goToExactRoute(contactCollectorGuestRoutePaths.form.path);
  };

  return {
    isValidatingAddress,
    addressOption,
    setAddressOption,
    suggestedAddress,
    tAdmin,
    handleOnConfirmAddressClick,
    handleOnBackClick
  };
};
