import React from 'react';
import { Flex, TextV2, ButtonV2, useDisclosure } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';
import { useAuth } from '@shared/components/AuthProvider';
import { darkButtonOverride } from '@apps/postOffice/components/Shared.styles';
import { ResponsiveDialog } from '@apps/card/components/ResponsiveDialog';
import { useClearContactListController } from './ClearContactList.controller';

interface ClearContactListProps
  extends Readonly<{
    contactCollectionId: string;
  }> {}

export const ClearContactList: React.FC<ClearContactListProps> = ({ contactCollectionId }) => {
  const dialogDisclosure = useDisclosure();
  const { t } = useTranslation('contactCollector');
  const tAdmin = t('admin');
  const { currentUser } = useAuth();
  const userIsSuperAdmin = currentUser.profile?.superAdmin;
  const { clearContactList, isClearContactListLoading, hasClearContactListError } = useClearContactListController(contactCollectionId, dialogDisclosure.onClose);

  const handleOnClearContactListClick = () => {
    clearContactList();
  };

  if (!userIsSuperAdmin) {
    return null;
  }

  return (
    <>
      <ResponsiveDialog
        isOpen={dialogDisclosure.isOpen}
        onClose={dialogDisclosure.onClose}
        header={() => <TextV2 typographyVariant="hed3">{tAdmin.clearContactList()}</TextV2>}
        content={() => (
          <Flex flexDirection="column" gap={8}>
            <TextV2 fontSize="15px" lineHeight="21px">
              {tAdmin.clearContactListDialog()}
            </TextV2>
            <Flex gap={4} flexDirection={{ _: 'column-reverse', sm2: 'row' }} width="100%" justifyContent="space-between">
              <ButtonV2 intent="neutral" shape="rounded" variant="outline" onClick={dialogDisclosure.onClose}>
                {tAdmin.cancel()}
              </ButtonV2>
              <ButtonV2 intent="neutral" shape="rounded" width="185px" onClick={handleOnClearContactListClick} loading={isClearContactListLoading}>
                {tAdmin.clearContactList()}
              </ButtonV2>
            </Flex>
            {hasClearContactListError && (
              <TextV2 typographyVariant="label2" color="negative5">
                {tAdmin.clearContactListError()}
              </TextV2>
            )}
          </Flex>
        )}
      />
      <Flex width="375px" marginBottom="5px">
        <TextV2 typographyVariant="label1" color="#4951EF" marginTop="24px">
          SUPER ADMIN ONLY
        </TextV2>
      </Flex>
      <ButtonV2 width="375px" overrides={darkButtonOverride} onClick={dialogDisclosure.onOpen} disabled={!contactCollectionId}>
        {tAdmin.clearContactList()}
      </ButtonV2>
    </>
  );
};
