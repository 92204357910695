import { useGetContactsByContactCollectionLazyQuery, useDeleteContactsByContactIdsMutation } from '@graphql/generated';

export const useClearContactListController = (contactCollectionId: string, dialogOnClose: () => void) => {
  const [getContactsAndClearContactList, { loading: queryLoading, error: queryError }] = useGetContactsByContactCollectionLazyQuery({
    variables: {
      contactCollectionId
    },
    batchMode: 'fast',
    fetchPolicy: 'no-cache',
    onCompleted: async data => {
      const contacts = data?.getContactsByContactCollection;
      if (contacts?.length) {
        const contactIds = contacts.map(contact => contact?.id ?? '');
        await deleteContactsByContactIdsMutation({ variables: { contactIds } });
      }
      dialogOnClose();
    }
  });
  const [deleteContactsByContactIdsMutation, { loading: mutationLoading, error: mutationError }] = useDeleteContactsByContactIdsMutation({
    refetchQueries: ['GetContactCollectionByEventIdAndNamesByEventHandle'],
    awaitRefetchQueries: true
  });
  return {
    isClearContactListLoading: queryLoading || mutationLoading,
    hasClearContactListError: !!(queryError || mutationError),
    clearContactList: getContactsAndClearContactList
  };
};
